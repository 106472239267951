body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #ffffff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: large;
  color: darkseagreen;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div {
      width: 350px;
      height: 400px;
      background-color: #000000;
      background-size: cover;

      position: absolute;
      top:0;
      bottom: 0;
      left: 0;
      right: 0;
        
      margin: auto;
      font-size:40px;
      text-align: center;
  }